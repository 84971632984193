.header-menu .menu-nav > .menu-item > .menu-link .menu-text {
  font-family: 'Nunito', sans-serif;
  font-weight: 900;
  letter-spacing: 1.12px;
}

.proposals {
  .table {
    thead {
      th {
        span {
          color: #00428b;
          font-family: 'Nunito', sans-serif;
          font-size: 14px;
        }
      }
    }
    tbody {
      tr {
        height: 32px;
        td {
          i {
            cursor: pointer;
          }
          .is-favorite {
            color: #1e7be1;
          }
          .id {
            color: #8c8c8c;
          }
        }
      }
    }
  }
}
.force-graph-container {
  overflow: hidden;
}
