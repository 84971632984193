//
// Notes
//

.timeline.timeline-3 {
  .timeline-items {
    margin: 0;
    padding: 0;

    .timeline-item {
      margin-left: get($timeline-3-config, media-size) / 2;
      border-left: get($timeline-3-config, border-width) solid $border-color;
      padding: 0 0 20px get($timeline-3-config, media-size);
      position: relative;

      .timeline-media {
        position: absolute;
        top: 0;
        left: -((get($timeline-3-config, media-size) + get($timeline-3-config, border-width))/2);
        border: 2px solid $border-color;
        border-radius: 100%;
        width: get($timeline-3-config, media-size);
        height: get($timeline-3-config, media-size);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        line-height: 0;

        i {
          font-size: 1.4rem;
        }

        .svg-icon {
          @include svg-icon-size(24px);
        }

        img {
          max-width: get($timeline-3-config, media-size) - get($timeline-3-config, border-width);
          max-height: get($timeline-3-config, media-size) - get($timeline-3-config, border-width);
          border-radius: 100%;
        }
      }

      .timeline-content {
        @include border-radius($border-radius-lg);
        position: relative;
        background-color: $gray-100;
        padding: 0.75rem 1.5rem;

        &:before {
          position: absolute;
          content: '';
          width: 0;
          height: 0;
          top: 10px;
          left: -(get($timeline-3-config, media-size) / 2);
          border-right: solid 10px $gray-100;
          border-bottom: solid 17px transparent;
          border-left: solid 17px transparent;
          border-top: solid 17px transparent;
        }
      }

      &:last-child {
        border-left-color: transparent;
        padding-bottom: 0;
      }
    }
  }
}
