//
// KTDatatable
//

// Base
.datatable:not(.table) {
  display: none;
}

.datatable.datatable-default {
  display: block;

  // Base
  > .datatable-table {
    background-color: get($datatable-config, bg);
    border-collapse: collapse;
    overflow: hidden;
    display: none;
    width: 100%;

    > .datatable-head,
    > .datatable-body,
    > .datatable-foot {
      visibility: hidden;
      display: block;

      .datatable-row {
        width: 100%;

        > .datatable-cell {
          // Row cell base
          vertical-align: middle;
          padding: get($datatable-config, cell-padding-y) get($datatable-config, cell-padding-x);
          font-size: 1rem;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          &.datatable-cell-left {
            text-align: left;

            > span {
              text-align: left;
            }
          }

          &.datatable-cell-right {
            text-align: right;

            > span {
              text-align: right;

              > i {
                right: 0;
              }
            }
          }

          &.datatable-cell-center {
            text-align: center;

            > span {
              text-align: center;
            }
          }

          &.datatable-cell-sort {
            cursor: pointer;

            i {
              font-size: 0.6rem;
            }
          }

          &.datatable-cell-resizing {
            cursor: col-resize;
          }

          > span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          // Row checkbox
          &.datatable-cell-check {
            text-align: left;

            > span {
              overflow: visible;

              > .checkbox {
                top: 2px;
                padding: 0;
                margin: 0 0 0 0;
              }
            }
          }

          // Row details
          &.datatable-toggle-detail {
            > span {
              width: 12px;
            }

            > .datatable-toggle-detail {
              display: inline-block;
              text-align: right;

              > i {
                font-size: 1.4rem;
                width: 12px;
              }
            }
          }

          // Cell dropdown menu
          .dropdown {
            display: inline-block;

            .dropdown-menu {
              margin-top: 0.5rem;
            }

            &.dropup {
              .dropdown-menu {
                margin-top: auto;
                margin-bottom: 0.5rem;
              }
            }

            .dropdown-menu.dropdown-menu-right {
              margin-right: -0.8rem;
              /*rtl:ignore*/
              margin-left: 65px;
            }

            .dropdown-menu.dropdown-menu-left {
              margin-left: -10rem;
            }
          }
        }
      }

      > .datatable-lock {
        border: 0;

        &.datatable-lock-left {
          z-index: 1;
          box-shadow: get($datatable-config, lock-shadow);
        }

        &.datatable-lock-right {
          box-shadow: get($datatable-config, lock-shadow);
        }

        &.datatable-lock-scroll {
          position: relative;
        }
      }
    }

    > .datatable-head,
    > .datatable-foot {
      .datatable-row {
        > .datatable-cell {
          > span {
            font-weight: 500;
            vertical-align: middle;
            color: get($datatable-config, head-color);
          }

          &.datatable-cell-sorted {
            > span {
              color: get($datatable-config, head-sorted-color);
            }
          }
        }
      }
    }

    > .datatable-head {
      .datatable-row {
        > .datatable-cell {
          padding: get($datatable-config, head-cell-padding-y) get($datatable-config, head-cell-padding-x);

          > span {
            > i {
              display: inline-block;
              position: relative;
              right: -10px;
              line-height: 0;
              vertical-align: middle;
              font-size: 1.1rem;
              color: get($datatable-config, head-icon-color);
            }
          }
        }
      }
    }

    > .datatable-body {
      &.ps {
        padding-bottom: 0;
      }

      .datatable-row {
        > .datatable-cell {
          font-weight: regular;
          transition: background 0.3s ease;

          > span {
            color: get($datatable-config, body-color);
          }

          &.datatable-cell-check {
            > span {
              > .checkbox {
                top: 1px;
              }
            }
          }

          &.datatable-cell-hover {
            transition: background 0.3s ease;
            background-color: get($datatable-config, row-bg-hover);
          }
        }

        &.datatable-row-even {
          > .datatable-cell {
            background-color: get($datatable-config, row-bg-even);
          }
        }

        &.datatable-row-active {
          > .datatable-cell {
            background-color: get($datatable-config, row-bg-active);
          }
        }

        &.datatable-row-hover:not(.datatable-row-active) {
          transition: background 0.3s ease;

          > .datatable-cell {
            transition: background 0.3s ease;
            background-color: get($datatable-config, row-bg-hover);
          }
        }
      }

      .datatable-row-detail {
        display: block;

        .datatable-detail {
          display: block;
          padding: 0 get($datatable-config, spacer);

          table {
            box-shadow: get($datatable-config, subtable-shadow);
          }

          .datatable-row {
            display: table-row;

            > .datatable-cell {
              padding: 1rem 1.5rem;
              text-align: left;
              vertical-align: top;

              > span {
                width: auto !important;
              }

              &:first-child {
                font-weight: 500;
              }

              &:last-child {
                font-weight: regular;
                padding-left: 1.5rem;
              }
            }
          }
        }
      }

      .datatable-toggle-detail {
        vertical-align: middle;

        i {
          transition: color 0.3s ease;
          color: get($datatable-config, detail-icon-color);

          &:before {
            line-height: 0;
            vertical-align: middle;
          }
        }

        &:hover,
        &.datatable-toggle-detail-active {
          i {
            transition: color 0.3s ease;
            color: get($datatable-config, detail-icon-color-hover);
          }
        }
      }
    }
  }

  // Bordered
  &.datatable-bordered {
    > .datatable-table {
      > .datatable-head,
      > .datatable-body,
      > .datatable-foot {
        .datatable-row {
          border-bottom: 1px solid get($datatable-config, row-border);

          &.datatable-row-subtable-expanded {
            border-bottom: 0;
          }
        }
      }

      > .datatable-body {
        .datatable-row {
          &:last-child {
            border-bottom: 0;
          }
        }

        tr:last-of-type {
          border-bottom: 0;
        }

        .datatable-row-detail {
          .datatable-detail {
            table {
              border-left: 1px solid get($datatable-config, row-border);
              border-right: 1px solid get($datatable-config, row-border);
            }
          }
        }
      }
    }
  }

  // Bordered
  &.datatable-head-custom {
    > .datatable-table {
      > .datatable-head,
      > .datatable-foot {
        .datatable-row {
          > .datatable-cell {
            > span {
              color: get($datatable-config, head-compact-color);
              font-size: get($datatable-config, head-compact-font-size);
              text-transform: get($datatable-config, head-compact-text-transform);
              font-weight: get($datatable-config, head-compact-font-weight);
              letter-spacing: get($datatable-config, head-compact-letter-spacing);
            }

            &.datatable-cell-sorted {
              > span {
                color: get($datatable-config, head-sorted-color);
              }
            }
          }
        }
      }
    }
  }

  // Header bg
  &.datatable-head-bg {
    > .datatable-table {
      > .datatable-head {
        .datatable-row {
          border-bottom: 0;

          > .datatable-cell {
            background-color: get($datatable-config, head-bg);

            &:first-child {
              border-top-left-radius: $border-radius;
              border-bottom-left-radius: $border-radius;
            }

            &:last-child {
              border-top-right-radius: $border-radius;
              border-bottom-right-radius: $border-radius;
            }
          }
        }
      }
    }
  }

  &.datatable-foot-bg {
    > .datatable-table {
      > .datatable-foot {
        .datatable-row {
          border-bottom: 0;

          > .datatable-cell {
            background-color: get($datatable-config, head-bg);

            &:first-child {
              border-top-left-radius: $border-radius;
              border-bottom-left-radius: $border-radius;
            }

            &:last-child {
              border-top-right-radius: $border-radius;
              border-bottom-right-radius: $border-radius;
            }
          }
        }
      }
    }
  }

  // Error message
  &.datatable-error {
    .datatable-body {
      padding: 30px;
      text-align: center;
    }
  }

  // Lock state
  &.datatable-lock {
    > .datatable-table {
      > .datatable-head,
      > .datatable-body,
      > .datatable-foot {
        > .datatable-lock {
          display: inline-block;
          position: relative;
          vertical-align: top;
          overflow: hidden;
        }
      }
    }
  }

  // Loaded state
  &.datatable-loaded {
    display: block;

    > .datatable-table {
      display: block;

      > .datatable-head,
      > .datatable-body,
      > .datatable-foot {
        visibility: visible;
        display: block;
        position: relative;

        .datatable-row {
          display: table;
          table-layout: initial;

          > .datatable-cell {
            //display: table-cell;
          }
        }
      }
    }
  }

  // Scrollable state
  &.datatable-scroll {
    > .datatable-table {
      display: block;

      > .datatable-head,
      > .datatable-body,
      > .datatable-foot {
      }

      > .datatable-head,
      > .datatable-foot {
        overflow: hidden;

        .datatable-row {
          position: relative;
        }
      }

      > .datatable-body {
        overflow: hidden;
      }
    }
  }

  // Subtable
  &.datatable-subtable {
    > .datatable-table {
      margin-bottom: 0;

      > .datatable-head,
      > .datatable-body,
      > .datatable-foot {
        .datatable-toggle-subtable {
          display: flex;
          justify-content: center;
          align-items: center;

          > i {
            line-height: 0;
            font-size: 1.4rem;
            color: get($datatable-config, subtable-toggle-icon);
          }

          &:hover {
            text-decoration: none;
          }
        }

        .datatable-row-subtable {
          display: table;
          width: 100%;
          border-top: 0;

          > .datatable-subtable {
            > .datatable {
              padding: 0 get($datatable-config, spacer);

              > .datatable-pager {
                padding: get($datatable-config, spacer) 0px;
              }
            }
          }
        }
      }

      > .datatable-body {
        .datatable-row {
          &.datatable-row-subtable-expanded {
            > .datatable-cell {
              background-color: get($datatable-config, subtable-expanded-bg) !important;
            }
          }
        }

        .datatable-row-subtable {
          background-color: get($datatable-config, row-bg-even);

          > .datatable-subtable {
            > .datatable {
              box-shadow: get($datatable-config, subtable-shadow);
            }
          }

          &.datatable-row-loading {
            > .datatable-subtable {
              > .datatable {
                > .datatable-table {
                  background-color: get($datatable-config, row-bg-even);
                }
              }
            }
          }
        }
      }
    }
  }

  // Pager
  > .datatable-pager {
    margin: 0px;
    padding: get($datatable-config, page-spacer) 0;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.datatable-pager-top {
      margin-bottom: 10px;
    }

    > .datatable-pager-nav {
      margin: 0;
      padding: 0;
      display: flex;

      > li {
        padding: 0;
        margin-right: 5px;
        display: inline-block;

        &:last-child {
          margin-right: 0;
        }

        > .datatable-pager-link {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: get($datatable-config, page-size);
          min-width: get($datatable-config, page-size);
          padding: 0.5rem;
          transition: $transition-link;
          position: relative;
          font-size: 0.9rem;
          line-height: 1rem;
          font-weight: 500;
          @include border-radius($border-radius);

          > i {
            font-size: 0.6rem;
            text-align: center;
            display: inline-block;
            line-height: 0;
            color: get($datatable-config, page-icon-color);
            transition: $transition-link;
          }

          color: get($datatable-config, page-color);

          &.datatable-pager-link-first,
          &.datatable-pager-link-prev,
          &.datatable-pager-link-next,
          &.datatable-pager-link-last {
            background-color: get($datatable-config, page-bg);
          }

          &.datatable-pager-link-disabled {
            opacity: 0.6;
            cursor: default;
          }

          &:hover:not(.datatable-pager-link-disabled) {
            opacity: 1;
            transition: $transition-link;
            background-color: get($datatable-config, page-bg-hover);
            color: get($datatable-config, page-color-hover);

            i {
              transition: $transition-link;
              color: get($datatable-config, page-icon-color-hover);
            }
          }

          &.datatable-pager-link-active {
            transition: $transition-link;
            background-color: get($datatable-config, page-bg-active);
            color: get($datatable-config, page-color-active);

            i {
              transition: $transition-link;
              color: get($datatable-config, page-icon-color-active);
            }
          }
        }

        > .datatable-pager-input {
          height: get($datatable-config, page-size);
          width: 3.5rem;
          text-align: center;
          transition: $transition-link;
          @include border-radius($border-radius !important);
          background-color: get($datatable-config, page-bg);
          border-color: get($datatable-config, page-bg);
          color: get($datatable-config, page-color);

          &:focus {
            transition: $transition-link;
            background-color: get($datatable-config, page-bg);
            border-color: get($datatable-config, page-bg);
            color: get($datatable-config, page-color);
          }
        }
      }
    }

    > .datatable-pager-info {
      display: flex;
      align-items: center;

      .datatable-pager-size {
        margin-right: 10px;

        .btn.dropdown-toggle {
          //border-radius: 3px !important;
          height: get($datatable-config, page-size);
          padding: 0.45rem 1rem;
          font-size: 1rem;
          font-weight: 500;
          border: 0 !important;
          color: get($datatable-config, page-info-color);
          background-color: get($datatable-config, page-info-bg) !important;
          @include border-radius($border-radius);

          .filter-option,
          &:after,
          i {
            transition: $transition-link;
            color: get($datatable-config, page-info-color);
          }
        }

        .btn.dropdown-toggle:focus,
        .btn.dropdown-toggle:hover,
        &.show > .btn.dropdown-toggle {
          border: 0 !important;
          color: get($datatable-config, page-info-color-hover) !important;
          background-color: get($datatable-config, page-info-bg-hover) !important;

          .filter-option,
          &:after,
          i {
            transition: $transition-link;
            color: get($datatable-config, page-info-color-hover) !important;
          }
        }

        .dropdown-menu.inner > li.selected > a span.check-mark {
          margin-top: -0.6rem;
        }

        .dropdown-menu {
          z-index: 100;

          .modal & {
            z-index: $zindex-modal + 1;
          }
        }
      }
    }
  }

  // Scrollbar
  .ps > .ps__rail-y > .ps__thumb-y,
  .ps > .ps__rail-x > .ps__thumb-x {
    background-color: get($datatable-config, scrollbar-bg);

    &:hover,
    &:focus {
      background-color: get($datatable-config, scrollbar-bg);
    }
  }
}

// Mobile mode
@include media-breakpoint-down(sm) {
}
